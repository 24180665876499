import request from './request'

// 对战-邀请对战
export function inviteMatch () {
  return request({
    url: "/business/battle/inviteMatch",
    method: "get",
    params: { bookId: 2 }
  })
}
// 对战-接受邀请
export function matchInvite (params) {
  return request({
    url: "/business/battle/matchInvite",
    method: "get",
    params
  })
}
// 对战-准备
export function ready (params) {
  return request({
    url: "/business/battle/ready",
    method: "get",
    params
  })
}
//绑定手机号
export function changeMobile (params) {
  return request({
    url: "/business/user/changeMobile",
    method: "post",
    data: params
  })
}
//验证旧手机号
export function verifyOldCode (params) {
  return request({
    url: "/business/user/verifyOldCode",
    method: "post",
    data: params
  })
}


// mobile
// : 
// "8615201030068"
// scene
// : 
// "BIND"

//获取手机验证码
// {mobile
// : 
// "8615201030068"
// scene
// : 
// "LOGIN"}
export function smsCode (params) {
  return request({
    url: "/sms/code",
    method: "post",
    data: params
  })
}

// 手机号登录{mobile: "8615201030068", smsCode: "9127", uuid: "493e176a1f1a4a0badb30806a3e691ba"}
export function smsLogin (params) {
  return request({
    url: "/sms/login",
    method: "post",
    data: params
  })
}

//微信登录
export function wxLogin (params) {
  return request({
    url: "/wx/login",
    method: "POST",
    data: params,
    // token: true
  })
}
//获取用户信息
export function getUserInfo (params) {
  return request({
    url: "/business/user/getInfo",
    method: "get",
    params
  })
}

// 对战-获取对方准备状态
export function getBattleReady (params) {
  return request({
    url: "/business/battle/getBattleReady",
    method: "get",
    params
  })
}
// 对战-等待开始对战
export function waitStartBattle (params) {
  return request({
    url: "/business/battle/waitStartBattle",
    method: "get",
    params
  })
}

// 对战-开始对战
export function startBattle (params) {
  return request({
    url: "/business/battle/startBattle",
    method: "get",
    params
  })
}

// 对战-获取题目和最后结果
export function question (params) {
  return request({
    url: "/business/battle/question",
    method: "get",
    params
  })
}

// 对战-提交题目答案
export function submitSelfResult (params) {
  return request({
    url: "/business/battle/submitSelfResult",
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data: params
  })
}


// 对战-获取对方答题结果
export function getQuestionBattleResult (params) {
  return request({
    url: "/business/battle/getQuestionBattleResult",
    method: "get",
    params
  })
}



//web端keyword 词典
export function Search (params) {
  return request({
    url: "/business/dict/search",
    method: "get",
    params
  })
}
// word
export function spellError (params) {
  return request({
    url: "/business/dict/spellError",
    method: "get",
    params
  })
}

//语料库?word=ho
export function queryWordVerb (params) {
  return request({
    url: "/business/dict/queryWordVerb",
    method: "get",
    params
  })
}
///business/dict/searchCorpus?keyword=hola&direction=es_to_ch&group=jt_total&pageNum=1&pageSize=15
// export function searchCorpus (params) {
//   return request({
//     url: "/business/dict/searchCorpus",
//     method: "get",
//     params
//   })
// }

// https://api.unodostech.com/business/dict/searchExample?word=hola&exampleType=reading&pageSize=5&pageNum=1
export function searchExample (params) {
  return request({
    url: "/business/dict/searchExample",
    method: "get",
    params
  })
}
// https://api.unodostech.com/business/dict/query/?word=hola&type=sp_ch
//sp_ch西汉词典
//sp_sp
//vp_ad
//sp_ch_et
//sp_ch_science 专业词典
export function moreExplain (params) {
  return request({
    url: "/business/dict/query/",
    method: "get",
    params
  })
}

//语料库
//https://api.unodostech.com/business/dict/searchCorpus?keyword=ho&direction=es_to_ch&group=jt_total&pageNum=1&pageSize=15
export function searchCorpus (params) {
  return request({
    url: "/business/dict/searchCorpus",
    method: "get",
    params
  })
}

//获取单词详情/business/dict/wordDetail   word
export function wordDetail (params) {
  return request({
    url: "/business/dict/wordDetail",
    method: "get",
    params
  })
}

//变位模式 1=陈述式,2=虚拟式,3=命令式,4=条件式,5=无人称式

//时态 1=一般现在时/肯定形式/infinitivo,2=现在完成时/否定形式/infinitivo compuesto,3=过去未完成时/gerundio,
//4=过去完成时/gerundio compuesto,5=将来未完成时/participio,6=将来完成时,7=简单过去时,8=先过去时

//人称(1=yo我,2=tú你,3=él/ella/ustedj 他/她/您,4=nosotro(a)s 我们,5=vosotr(o)s 你们,6=ellos/ellas/ustedes他们/她们/您们）

// https://api.unodostech.com/business/dict/verbChange/?word=amar
export function verbChange (params) {
  return request({
    url: "/business/dict/verbChange",
    method: "get",
    params
  })
}


// 课程接口
//栏目树
export function subjectTree (params) {
  return request({
    url: "/business/class/subjectTree",
    method: "get",
    params
  })
}

export function subjectList (params) {
  return request({
    url: "/business/class/subjectList",
    method: "get",
    params
  })
}

//专辑列表
export function albumList (params) {
  return request({
    url: "/business/class/albumList",
    method: "get",
    params
  })
}

//pdf资料
export function pdfList (params) {
  return request({
    url: "/business/class/pdfList",
    method: "get",
    params
  })
}

//获取课节列表
export function classList (params) {
  return request({
    url: "/business/class/classList",
    method: "get",
    params
  })
}


export function getVideoPlayAuth (params) {
  return request({
    url: "business/vod/getVideoPlayAuth",
    method: "get",
    params
  })
}

// s/vod/getVideoList?videolds=c3868ad2c92645a49e14c26e76174c93,b48fb709168744b687d009f45f9eb78b


export function getSchoolList () {
  return request({
    url: "/public/getSchoolList",
    method: "get",
  })
}

// 填写用户信息
export function updateBaseInfo (params) {
  return request({
    url: "/business/user/updateBaseInfo",
    method: "post",
    data: params
  })
}
// 

export function updateFavor (params) {
  return request({
    url: "/business/class/updateFavor",
    method: "get",
    params
  })
}


export function updateProgress (params) {
  return request({
    url: "/business/class/updateProgress",
    method: "get",
    params
  })
}

//语伴
export function getStreamId (params) {
  return request({
    url: "/business/dict/chatGPT/streamId",
    method: "post",
    data: params
  })
}

export function dochatgpt (params) {
  return request({
    url: "/business/chatgpt/",
    method: "post",
    data: params
  })
}


export function gethistory (params) {
  return request({
    url: "/business/chatgpt/list",
    method: "get",
    params
  })
}

//课程历史记录
export function getLatestView (params) {
  return request({
    url: "/business/class/latestView",
    method: "get",
    params
  })
}

//建议反馈

export function getContentType (params) {
  return request({
    url: "/public/dict/type/bus_content_type",
    method: "get",
    params
  })
}




export function addSuggestContent (params) {
  return request({
    url: "/business/feedback/add",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: params
  })
}

//支付
export function rankList (params) {
  return request({
    url: "/public/rank/list",
    method: "get",
    params
  })
}

// 下订单
export function getOrder (params) {
  return request({
    url: "/business/order/saveRankOrder",
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data: params
  })
}

//预支付
export function getImgCode (params) {
  return request({
    url: "/business/order/pay",
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params
  })
}

//订单详情
export function orderDetail (orderId) {
  return request({
    url: "/business/order/" + orderId,
    method: "get",
  })
}

//卡列表
export function getRankList (params) {
  return request({
    url: "/public/rank/list",
    method: "get",
    params
  })
}
//订单列表/api-biz/business/order/list
export function orderList (params) {
  return request({
    url: "/business/order/list",
    method: "get",
    params
  })
}
//取消订单/api-biz/business/order/cancel/{orderId}
export function cancelOrder (orderId) {
  return request({
    url: "/business/order/cancel/" + orderId,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
  })
}

//屏幕取词
export function screenQuery (params) {
  return request({
    url: "business/dict/screenQuery/",
    method: "get",
    params
  })
}

//删除/business/class/latest?albumld=44

export function delhistory (params) {
  return request({
    url: "/business/class/latest",
    method: "DELETE",
    params
  })
}


//每日一词
export function everydayList (params) {
  return request({
    url: "/business/banner/everydayList",
    method: "get",
    params
  })
}


//androi包
export function appConfig () {
  return request({
    url: "/public/appConfig",
    method: "get",
  })
}
//在线搜索接口/business/dict/googleSearch?word=dotef
export function googleSearch (params) {
  return request({
    url: "/business/dict/googleSearch",
    method: "get",
    params
  })
}
