<template>
  <el-dialog
    :visible.sync="loginDialog"
    :before-close="handleClose"
    :modal-append-to-body="false"
  >
    <div class="dialogleft" ref="dialogleft" style="">
      <div class="changetel" @click="changecode(1)">
        <img src="./images/left.png" alt="" /><a
          href="#"
          class="font20500 black2"
          >手机号登录</a
        >
      </div>
      <div class="qrcode1" id="qrcode1">
        <!-- <p class="titlefont2">微信扫码登录</p> -->
        <!-- <img src="../../assets/images/code.png" alt="" /> -->
        <wxLoginCom
          :scope="'snsapi_login'"
          :theme="'black'"
          href="data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7CiBib3JkZXI6IG5vbmU7CiB3aWR0aDogMTQ4cHg7CiBoZWlnaHQ6IDE0OHB4Owp9Cg=="
        ></wxLoginCom>
      </div>
      <p class="beizhufont3">
        未注册的手机号将<br />自动创建「西语派学道」账号
      </p>
    </div>
    <div class="dialogcenter" ref="dialogcenter" style=""></div>
    <div class="dialogright" ref="dialogrigth" style="">
      <p class="titlefont12">西语派学道</p>
      <el-form :model="form">
        <el-form-item class="tel">
          <el-input placeholder="请输入手机号" v-model="form.mobile">
            <template slot="prepend">+86</template>
          </el-input>
        </el-form-item>
        <el-form-item class="code">
          <el-input placeholder="输入验证码" v-model="form.smsCode"></el-input>
          <el-button @click="getSMS" :disabled="!!timer">{{ text }}</el-button>
        </el-form-item>
        <p class="beizhufont3">
          注册登录即代表同意<span class="fontcolor"
            ><a href="https://admin.unodostech.com/s/2LKcb4" target="_blank"
              >《用户协议》</a
            ><a href="https://admin.unodostech.com/s/2LKcb2" target="_blank"
              >《隐私政策》</a
            ></span
          >
        </p>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onClick_1">登录/注册</el-button>
      </div>
      <div class="changecode">
        <a href="#" class="font18500 black1" @click="changecode(2)"
          >微信扫码登录</a
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import wxLoginCom from "./wxLogin"
import eventBus from "@/utils/eventBus.js"
// import wxLogint from "vue-wxlogin"
import {
  smsCode,
  smsLogin,
  wxLogin
} from "@/api"
export default {
  name: 'Login',
  components: {
    wxLoginCom
    // wxLogint
  },
  props: ['loginDialog', 'forword'],


  data () {
    return {
      // redirect_uri: encodeURIComponent("https://unodostech.com/"),
      // dialogFormVisible: false,
      // forword: '',
      form: {
        mobile: "",
        smsCode: "",
        uuid: "",
        wxFrom: "web",
        iswx: "1",//微信登录2 其它1
      },
      timer: null,
      text: "获取验证码",
      ac: "86",
      // showCode: false,
    }
  },
  mounted () {

    // console.log(this.loginDialog, 'loginDialog')
    eventBus.$on("WeiLogin", (code, iswx) => {
      console.log("code", iswx)
      this.iswx = iswx
      this.onWxLogin(code)
    })
  },
  methods: {
    changecode (type = 2) {
      if (type == 1) {
        this.$refs.dialogleft.style.display = "none"
        this.$refs.dialogcenter.style.display = "none"
        this.$refs.dialogrigth.style.display = "block"
      } else {
        console.log(this.$refs.dialogleft.style)
        this.$refs.dialogleft.style.display = "block"
        this.$refs.dialogcenter.style.display = "none"
        this.$refs.dialogrigth.style.display = "none"
      }
    },
    handleClose (done) {
      // done()
      // this.$bus.$emit("changeShowLogin", false)
      this.$bus.$emit('showLogin', { showLogin: false, forword: '' })
      this.form.mobile = ""
      this.form.smsCode = ""
      this.timer = null
      this.text = "获取验证码"
      this.ac = "86"
    },
    getSMS () {
      if (!/^[1][35789][0-9]{9}$/.test(this.form.mobile)) {
        console.log("dfsisf")
        // if (!/^[0-9]*$/.test(this.form.mobile)) {
        this.$message.error("请输入正确手机号")
        return
      }

      if (this.text === "获取验证码") {
        smsCode({
          mobile: this.ac + this.form.mobile,
          // uuid: this.form.uuid,
          // code: this.form.code,
          scene: "LOGIN"
        }).then(res => {
          if (res.code == 500) {
            this.$message.error(res.msg)
            // this.getImg()
            return
          }
          let num = 60
          this.timer = setInterval(() => {
            num--

            if (num === 0) {
              clearInterval(this.timer)
              this.timer = null
              this.text = `获取验证码`
            }
            else {
              this.text = `重新获取(${num}s)`
              // console.log(num,'num');
            }
          }, 1000)

          this.form.uuid = res.uuid
          // this.form.smsCode = res.smsCode
        })
      } else {
      }
    },
    onClick_1 () {
      if (!this.form.mobile) {
        this.$message.error("请输入手机号")
        return
      }
      if (!/^[1][35789][0-9]{9}$/.test(this.form.mobile)) {
        // if (!/^[0-9]*$/.test(this.form.mobile)) {
        this.$message.error("请输入正确手机号")
        return
      }
      if (!this.form.smsCode) {
        this.$message.error("请输入验证码")
        return
      }
      smsLogin({ ...this.form, mobile: this.ac + this.form.mobile }).then(
        res => {
          this.login(res)
        }
      )
    },
    login (res) {
      if (res.code === 200) {
        // localStorage.setItem("token", "Bearer " + res.token)
        // eventBus.$emit("getUserInfo")
        // this.$message.success("登録成功")
        // this.$router.replace({
        //   path: this.$route.query.forword ? this.$route.query.forword : "/"
        // })
        localStorage.setItem("token", "Bearer " + res.token)
        this.$message.success("登录成功")
        if (this.iswx == 2) {
          // this.$router.replace({
          //   path: '/'
          // })
          this.$router.replace('/').catch(err => { })
        } else if (this.forword) {
          this.$router.replace({
            // path: this.$route.query.forword ? this.$route.query.forword : "/"
            path: this.forword
          })
        }


        // this.dialogFormVisible = false
        this.$bus.$emit('showLogin', { showLogin: false, forword: '' })
        this.$bus.$emit("getUserInfo1")
        // this.$bus.$emit("outLogin")
        // eventBus.$emit("openBindMobile")


      } else {
        this.$message.error(res.msg)
        if (this.iswx == 2) {
          // this.$router.replace({
          //   path: '/'
          // })
          this.$router.replace('/').catch(err => { })
        }
      }
    },
    onWxLogin (code) {
      wxLogin({ wxFrom: 'web', code: code }).then(
        res => {
          this.login(res)
        }
      )

    }


  },
  // watch: {
  //   "loginDialog" (newVal, oldVal) {
  //     console.log(newVal, 'newVal')
  //     this.dialogFormVisible = newVal
  //   }
  // },
  beforeDestroy () {

    // this.$bus.$off('WeiLogin')
    eventBus.$off('WeiLogin')
  }

}
</script>

<style lang="less" scoped>
.impowerBox {
  .title {
    display: none;
  }
}
.impowerBox .status.status_browser {
  display: none;
}
.impowerBox .qrcode {
  border: none;
  width: 200px;
  height: 200px;
}
.impowerBox .status {
  display: none;
}
.changetel {
  display: none;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}
.changecode {
  padding-top: 10px;
  text-align: center;
  display: none;
  a {
    text-decoration: underline;
  }
}
/deep/.el-dialog {
  width: 774px;
  height: 425px;
  // opacity: 1;
  border-radius: 12px;
  // background: rgba(255, 255, 255, 1);

  .dialogleft {
    // width: 310px;
    width: 100%;
    height: 100%;
    // padding: 38px;
    left: 883px;
    .qrcode1 {
      width: 190px;
      height: 234px;
      margin: 20px auto;
      opacity: 1;
      border-radius: 12px;
      box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
      padding: 16px;
      text-align: center;
      p {
        font-weight: 500;
        text-align: center;
      }
      img {
        width: 150px;
        height: 150px;
        opacity: 1;
        margin-top: 20px;
      }
    }

    p {
      text-align: center;
      margin-top: 20px;
      line-height: 18.56px;
    }
  }
  .dialogcenter {
    width: 4px;
    height: 350px;
    opacity: 1;
    border-radius: 210px;
    background: rgba(238, 238, 238, 1);
  }
  .dialogright {
    flex: 1;
    padding: 42px 60px;
    .fontcolor {
      color: rgba(67, 207, 124, 1);
    }
    .titlefont12 {
      text-align: center;
    }
  }
}
/deep/.el-dialog__body {
  display: flex;
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-input-group__prepend {
  border-radius: 8px 0px, 0px, 8px;
  background: rgba(245, 245, 247, 1);
  width: 61px;
  height: 48px;
  color: rgba(29, 29, 31, 1);
  font-size: 16px;
  font-weight: 400;
}

/deep/.el-input__inner {
  height: 48px;
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
  color: rgba(29, 29, 31, 1);
  font-size: 16px;
  font-weight: 400;
}
/deep/.tel .el-input__inner {
  border-radius: 0 8px 8px 0;
}
/deep/.code .el-form-item__content {
  display: flex;
}
/deep/.code .el-button {
  width: 112px;
  height: 48px;
  border-radius: 8px;

  border: 1px solid rgba(238, 238, 238, 1);
  margin-left: 8px;
}
/deep/.dialog-footer .el-button {
  width: 339px;
  height: 50px;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(94, 205, 143, 1) 0%,
    rgba(103, 224, 168, 1) 100%
  );
}
/deep/.el-button--primary {
  border: none;
}
/deep/.el-form {
  margin: 30px 0;
}
@media (max-width: 1116px) {
  .dialogleft {
    display: none;
  }
  .dialogcenter {
    display: none;
  }
  /deep/.el-dialog {
    width: 460px;
    height: 425px;
  }
  /deep/ .el-dialog .dialogright {
    padding: 0px 30px;
  }
  .changecode {
    display: block;
  }
  .changetel {
    display: flex;
  }
  .dialogleft {
    margin: 0 auto;
  }
}
@media (min-width: 1116px) {
  .dialogleft {
    display: block !important;
  }
  .dialogcenter {
    display: block !important;
  }
  .dialogright {
    display: block !important;
  }
}
</style>