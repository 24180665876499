export default [

    {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/Home'),
        meta: { show: true, isweb: true, index: 0 },
    },
    {
        path: '/aboutus',
        component: () => import('@/pages/AboutUs'),
        meta: { show: true, isweb: true, index: 1 },
    },
    {
        path: '/download',
        component: () => import('@/pages/DownLoad'),
        meta: { show: true, isweb: true, index: 2 },
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('@/pages/Search'),
        meta: { show: true, isweb: true, index: 3 },
    },
    {
        path: '/course',
        component: () => import('@/pages/Course'),
        meta: { show: true, isweb: true, index: 4 },
    },
    {
        path: '/history',
        component: () => import('@/pages/CourseHistory'),
        meta: { show: true, isweb: true, index: 4 },
    },
    {
        path: '/coursedetail',
        name: "CourseDetail",
        component: () => import('@/pages/CourseDetail'),
        meta: { show: true, isweb: true, index: 4 },
    },
    {
        path: '/find',
        component: () => import('@/pages/Find'),
        meta: { show: true, isweb: true, index: 5 },
    },
    {
        path: '/finddetail',
        name: "FindDetail",
        component: () => import('@/pages/FindDetail'),
        meta: { show: true, isweb: true, index: 5 },
    },
    {
        path: '/partner',
        component: () => import('@/pages/Partner'),
        meta: { show: true, isweb: true, index: 6 },
    },
    {
        path: '/preparation',

        component: () => import('@/pages/BattlePre'),
        meta: { show: false, isweb: false },
    }, {
        path: '/result',
        name: 'result',
        component: () => import('@/pages/BattleResult'),
        meta: { show: false, isweb: false },
    }, {
        path: '/subject',
        name: 'Subject',
        component: () => import('@/pages/BattleSubject'),
        meta: { show: false, isweb: false },
    },
    {
        path: '/wrong',
        component: () => import('@/pages/BattleWrong'),
        meta: { show: false, isweb: false },
    },
]