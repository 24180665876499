<template>
  <el-dialog
    :visible.sync="isShowBuy"
    :show-close="false"
    :modal-append-to-body="false"
    :before-close="handleClose"
  >
    <div class="box">
      <div :class="isVip ? 'boxtop goldback' : 'boxtop'">
        <div class="tophead">
          <div class="headicon">
            <img
              :src="userInfo.avatar || require('../images/head.png')"
              alt=""
            />
          </div>
          <div class="nicks">
            <p class="font16500 black1">{{ userInfo.nickName }}</p>
            <p v-if="isVip" class="font12400 black1">当前是普通会员</p>
            <!-- <p v-else class="font12400 black1">当前是普通会员</p> -->
          </div>
        </div>
        <div class="close" @click="handleClose()">
          <img src="./images/close.png" alt="" />
        </div>
      </div>
      <div class="boxcontent">
        <div class="conleft">
          <div class="lefttop">
            <img src="./images/gold.png" alt="" />
          </div>
          <div class="leftend font14400 grey1">
            <p>
              <span class="rad"></span>
              全部精品课程
            </p>
            <p><span class="rad"></span>完整语料库</p>
            <p><span class="rad"></span>全部动词搭配</p>
            <p><span class="rad"></span>语伴</p>
            <p><span class="rad"></span>全部资料下载</p>
            <p>
              <span class="rad"></span>背单词所有词书<span
                class="font10400 grey2"
                >&nbsp;移动端专享</span
              >
            </p>
            <p>
              <span class="rad"></span>签到双倍积分
              <span class="font10400 grey2">&nbsp;移动端专享</span>
            </p>
            <p><span class="rad"></span>畅享影视</p>
          </div>
        </div>
        <div class="conright">
          <div class="top">
            <p class="font16500 black1">购买时长</p>
            <div class="cardlist">
              <div
                v-for="(rankitem, rankindex) in rankList"
                :key="rankindex"
                :class="
                  rankActive == rankindex
                    ? 'carditem activeitem cursorpointer'
                    : 'carditem cursorpointer'
                "
                @click="changeActive(rankindex)"
              >
                <p class="font14400 grey3 title">{{ rankitem.rankName }}</p>
                <p class="yellow1 font32400">
                  <i class="font14400">￥</i>{{ rankitem.salePrice }}
                </p>
                <p class="grey4 linethrow">￥{{ rankitem.price }}</p>
                <div class="yellobutton font12400 color3">
                  立省{{ rankitem.price - rankitem.salePrice }}元
                </div>
                <div class="tehui font12400 white" v-if="rankindex == 0">
                  首月特惠
                </div>
              </div>
              <!-- <div class="carditem">
                <p class="font14400 grey3 title">季度会员</p>
                <p class="yellow1 font32400"><i class="font14400">￥</i>98</p>
                <p class="grey4 linethrow">￥128元/季</p>
                <div class="yellobutton font12400 color3">立省30元</div>
              </div>
              <div class="carditem">
                <p class="font14400 grey3 title">季度会员</p>
                <p class="yellow1 font32400"><i class="font14400">￥</i>98</p>
                <p class="grey4 linethrow">￥128元/季</p>
                <div class="yellobutton font12400 color3">立省30元</div>
              </div> -->
            </div>
          </div>
          <div class="end">
            <p class="font16500 black1">支付方式</p>
            <div class="endbox">
              <div class="endleft">
                <!-- @changeVisible="doChangeVisible" -->
                <pay
                  :orderId="orderId"
                  :payType="payType"
                  v-if="isShowBuy"
                  ref="paybox"
                ></pay>
              </div>
              <div class="endright">
                <p>
                  实付：￥{{
                    rankList[rankActive] && rankList[rankActive].salePrice
                  }}元
                </p>
                <div
                  @click="payType = 'WXPAY'"
                  :class="
                    payType == 'WXPAY'
                      ? 'paybutton activepaybutton'
                      : 'paybutton'
                  "
                >
                  <img src="./images/pay1.png" alt="" /><span
                    class="font12400 yellow1"
                    >微信扫码支付</span
                  >
                </div>
                <div
                  @click="payType = 'ALIPAY'"
                  :class="
                    payType == 'ALIPAY'
                      ? 'paybutton activepaybutton'
                      : 'paybutton'
                  "
                >
                  <img src="./images/pay2.png" alt="" /><span
                    class="font12400 grey1"
                    >支付宝扫码支付</span
                  >
                </div>
              </div>
            </div>
            <div style="width: 100%; text-align: center">
              <el-checkbox v-model="checked"
                ><span class="font10400 grey5"
                  >我已阅读并同意<span class="color1"
                    >《服务协议》《续费规则》《第三方支付协议》</span
                  ></span
                ></el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getUserInfo, rankList, getOrder
} from "@/api"
import pay from "../pay"
export default {
  name: "Buy",
  components: { pay },
  data () {
    return {
      baseUrl: window.__baseUrl__,
      dialogVisible: false,
      userInfo: {},
      checked: true,
      isVip: false,
      // datekey: Date.now(),
      rankList: [],
      rankActive: 0,
      payType: "WXPAY",
      orderId: ''
    }
  },

  props: ['isShowBuy'],
  mounted () {
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {}
    // this.userInfo.avatar = this.userInfo.avatar ? (this.userInfo.avatar.indexOf('http') == -1) ? this.baseUrl + this.userInfo.avatar : this.userInfo.avatar : require("../images/head1.png")
    this.getIsVip()
    // this.getUserInfo()
    this.getRankList()
    // this.$bus.$on("buyChangeVisible", (val) => {
    //   this.doChangeVisible(val)
    //   if (val) {
    //     this.$refs.paybox.$forceUpdate()
    //   }
    // })

  },
  methods: {
    handleClose () {
      console.log('close')
      this.$bus.$emit("doShowBuy", false)
      this.$bus.$emit("doCloseCode")
    },
    changeActive (index) {
      this.rankActive = index
      this.getOrder()
    },
    getOrder () {

      let { rankId, price: total, salePrice: realTotal } = this.rankList[this.rankActive]
      let orderparam = {
        rankId,
        total,
        realTotal
      }
      getOrder(orderparam).then((d) => {
        this.orderId = d.orderId
      })


    },
    getRankList () {
      rankList().then(d => {
        console.log(d, 'ddd')
        this.rankList = d.data
        this.getOrder()
      })

    },
    getUserInfo () {
      getUserInfo().then(d => {
        this.userInfo = d.user
        this.userInfo.avatar = (this.userInfo.avatar.indexOf('http') == -1) ? this.baseUrl + this.userInfo.avatar : this.userInfo.avatar


      })
    },
    getIsVip () {
      let nowtimestamp = new Date().getTime()
      if (this.userInfo.vipValidityDate) {
        let viptime = Date.parse(this.userInfo.vipValidityDate)
        this.isVip = viptime > nowtimestamp
      }
    },
    // doChangeVisible (newData) {
    //   this.dialogVisible = newData
    //   if (!newData) {
    //     this.$bus.$emit("doCloseCode")
    //   }
    // },
  },
  // watch: {
  //   isShowBuy (newVal, oldVal) {
  //     this.dialogVisible = newVal
  //     if (newVal) {
  //       this.$refs.paybox.$forceUpdate()
  //     }

  //   }
  // },
  // beforeDestroy () {
  //   this.$bus.$off("buyChangeVisible")
  // }

}
</script>

<style lang="less" scoped>
/deep/.el-checkbox__inner {
  // width: 12px;
  // height: 12px;
  // line-height: 12px;
  border-radius: 50%;
}
/deep/.el-dialog {
  width: 636px;
  height: 513px;
  opacity: 1;
  z-index: 3000;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);

  // border: 2px solid rgba(255, 255, 255, 1);

  // box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  // backdrop-filter: blur(15px);
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding: 0;
}
.box {
  width: 636px;
  height: 513px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(245, 246, 247, 1);
  // position: absolute;
  // left: 50%;
  // top: 28vh;
  // transform: translateX(-50%);
  .boxtop {
    width: 100%;
    height: 80px;
    opacity: 1;
    border-radius: 12px 12px 0px 0px;
    background: linear-gradient(
      131.04deg,
      rgba(186, 220, 230, 1) 0%,
      rgba(183, 224, 237, 1) 54.18%,
      rgba(163, 221, 240, 1) 100%
    );
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tophead {
      display: flex;
      align-items: center;
    }

    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .headicon {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      img {
        border-radius: 50%;
      }
    }
  }
  // .sinalback {
  // background: linear-gradient(
  //   148.48deg,
  //   rgba(254, 228, 182, 1) 0%,
  //   rgba(253, 205, 121, 1) 59.21%,
  //   rgba(248, 212, 140, 1) 100%
  // );
  // }
  .goldback {
    background: linear-gradient(
      148.48deg,
      rgba(254, 228, 182, 1) 0%,
      rgba(253, 205, 121, 1) 59.21%,
      rgba(248, 212, 140, 1) 100%
    );
  }

  .boxcontent {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    .conleft {
      width: 212px;
      height: 401px;
      opacity: 1;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
      padding: 16px;
      .lefttop {
        height: 93px;
        border-bottom: 2px solid rgba(238, 238, 238, 1);
        img {
          height: 79.5px;
        }
      }
      .leftend {
        line-height: 2.5;
        p {
          display: flex;
          align-items: center;
          .rad {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background: rgba(119, 119, 119, 1);
            margin: 0 5px;
          }
        }
      }
    }
    .conright {
      width: 382px;
      height: 401px;
      // background: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        width: 100%;
        height: 204px;
        padding: 16px;

        opacity: 1;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
        overflow-x: auto;
        .cardlist {
          display: flex;
          justify-content: space-around;
          margin-top: 16px;
          .carditem {
            width: 110px;
            height: 130px;
            padding: 10px 0;
            text-align: center;
            opacity: 1;
            border-radius: 10px;
            background: rgba(245, 245, 247, 1);
            position: relative;
            .yellobutton {
              width: 67px;
              height: 20px;
              margin: 5px auto 0;
              line-height: 18px;
              opacity: 1;
              border-radius: 20px;
              background: rgba(255, 239, 209, 1);
            }
            .tehui {
              width: 62px;
              height: 22px;
              line-height: 20px;
              position: absolute;
              left: -2px;
              top: -10px;
              opacity: 1;
              border-radius: 11px 11px 11px 0px;
              background: linear-gradient(
                90deg,
                rgba(255, 125, 102, 1) 0%,
                rgba(235, 131, 52, 1) 100%
              );
            }
          }
          .activeitem {
            background: linear-gradient(
              90deg,
              rgba(253, 247, 202, 1) 0%,
              rgba(249, 228, 198, 1) 100%
            );

            border: 2px solid rgba(244, 185, 121, 1);
            .title {
              color: rgba(99, 49, 14, 1);
            }
            .yellobutton {
              background: linear-gradient(
                90deg,
                rgba(241, 160, 86, 1) 0%,
                rgba(237, 115, 48, 1) 100%
              );
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
      .end {
        width: 100%;
        height: 187px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
        padding: 16px;

        .endbox {
          display: flex;
          // flex-direction: column;
          justify-content: space-around;
        }
        .endleft {
          width: 110px;
          height: 110px;
        }
        .endright {
          // flex: 1;
          width: 132px;
          .paybutton {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 4px;
            margin-top: 10px;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>