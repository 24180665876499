<template>
  <el-dialog
    title="建议反馈"
    :visible.sync="isShowSuggest"
    width="615px"
    height="484px"
    :modal-append-to-body="false"
    :before-close="cancel"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-form-item label="反馈类型" required prop="contentType">
        <el-radio-group v-model="ruleForm.contentType">
          <el-radio
            :label="item.dictValue"
            v-for="item in contentTypeList"
            :key="item.dictValue"
            >{{ item.dictLabel }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="补充详情" required prop="feedbackInfo">
        <el-input
          type="textarea"
          rows="4"
          placeholder="请输入内容"
          v-model="ruleForm.feedbackInfo"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="图片">
        <el-upload
          action="#"
          list-type="picture-card"
          :auto-upload="false"
          :on-remove="handleRemove"
          :on-change="changeUpload"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="联系方式">
        <el-input v-model="input" placeholder="请输入内容"></el-input>

        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" @click="cancel()">取 消</el-button>
      <el-button type="info" @click="addSuggestBySel()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getContentType, addSuggestContent
} from "@/api"
export default {
  name: "Suggest",
  data () {
    return {
      // dialogVisible: false,
      ruleForm: {
        feedbackInfo: '',
        contentType: '',
        contentName: '',//被反馈内容
        picFile: ''
      },
      rules: {
        contentType: [
          { required: true, message: '请选择反馈类型', trigger: 'blur' },
        ],
        feedbackInfo: [
          { required: true, message: '请输入补充详情', trigger: 'blur' },
        ]
      },
      contentTypeList: [],
      fileList: []

    }
  },
  props: ['isShowSuggest'],

  mounted () {
    this.getContentType()
  },
  methods: {
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    changeUpload (file, fileList) {
      this.fileList = fileList
    },
    getContentType () {
      console.log(12345)
      getContentType().then(res => {
        if (res.code == 200) {
          // console.log(res, 'rd')
          this.contentTypeList = res.data
        }
      })

    },
    addSuggestBySel () {
      this.$refs['ruleForm'].validate((valid) => {
        console.log(valid, 'validvalid')
        if (valid) {
          let formData = new FormData()
          // 将上传的文件放到数据对象中
          formData.append("contentType", this.ruleForm.contentType)
          formData.append("contentName", this.contentTypeList[this.ruleForm.contentType].dictLabel)
          formData.append("feedbackInfo", this.ruleForm.feedbackInfo)
          this.fileList.forEach((file) => {
            formData.append("picFile", file.raw)
          })

          addSuggestContent(formData).then(res => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              // this.dialogVisible = false
              this.$bus.$emit("doSuggest", false)
              this.fileList = []
              this.resetForm()

            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })

    },
    resetForm () {
      this.$refs['ruleForm'].resetFields()
    },
    cancel () {
      this.$bus.$emit("doSuggest", false)
    }
  },
  // watch: {
  //   "isShowSuggest" (newVal, oldVal) {
  //     this.dialogVisible = newVal

  //   }
  // },

}
</script>

<style  lang="less" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px 0;
  height: 72%;
  overflow-y: auto;
}
/deep/.el-textarea__inner {
  border-radius: 8px;

  border: 1px solid rgba(238, 238, 238, 1);
}
/deep/.el-upload-list--picture-card .el-upload-list__item,
/deep/.el-upload--picture-card {
  width: 64px;
  height: 64px;
  border: 1px dashed rgba(238, 238, 238, 1);
}
/deep/.el-upload--picture-card {
  line-height: 72px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-dialog {
  height: 484px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);

  border: 2px solid rgba(255, 255, 255, 1);
  padding: 0 20px;
}
// /deep/.el-button {
//   width: 74px;
//   height: 36px;
//   line-height: 36px;
//   padding: 0;
//   opacity: 1;
//   border-radius: 8px;
//   background: rgba(245, 245, 247, 1);

//   font-size: 18px;
//   font-weight: 500;
//   color: rgba(67, 207, 124, 1);
//   border: none;
// }
// /deep/.activebutton {
//   background: linear-gradient(
//     135deg,
//     rgba(94, 205, 143, 1) 0%,
//     rgba(103, 224, 168, 1) 100%
//   );
//   color: rgba(255, 255, 255, 1);
// }
/deep/.el-button--info {
  background-color: rgba(245, 245, 247, 1);
  color: rgba(67, 207, 124, 1);
}
/deep/.el-button:hover {
  background: linear-gradient(
    135deg,
    rgba(94, 205, 143, 1) 0%,
    rgba(103, 224, 168, 1) 100%
  );
  color: #fff;
}
/deep/.el-button {
  border: none;
}

/deep/.el-form-item {
  margin-top: 10px;
}
/deep/.el-form-item__label {
  font-size: 16px;
  font-weight: 400;
  color: rgba(29, 29, 31, 1);
}
/deep/.el-radio {
  color: rgba(29, 29, 31, 1);
  width: 105px;
  padding-left: 30px;
  margin: 10px 0;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #1d1d1f;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(212, 48, 48, 1);
  background: rgba(212, 48, 48, 1);
}
/deep/.el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  color: rgba(212, 48, 48, 1);
}
/deep/.el-radio-group {
  width: 100%;
  // height: 76px;
  border-radius: 8px;
  // padding: 14px 0px;

  border: 1px solid rgba(238, 238, 238, 1);
}
</style>