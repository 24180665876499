//axios进行二次封装
import axios from 'axios'
//进度条
import nprogress from 'nprogress'
import router from "@/router"
// import eventBus from "@/utils/eventBus.js"


//因为进度条样式咱们没有引入
// import "nprogress/nprogress.css";
//引入Vuex仓库模块
// import store from '@/store'
// window._apiUrls = window._apiUrls || {}
window.__baseUrl__ =
    process.env.NODE_ENV == "production"
        ? window._apiUrls.baseUrl
        : process.env.NODE_ENV == "test"
            ? window._apiUrls.test
            : window._apiUrls.dev
// window.__baseUrl__ = "https://test.unodostech.com/api-bus"
//创建axios实例[创建出来的实例即为axios，只不过可以配置一些东西]
let instance = axios.create({
    //可以给请求路径中添加一些参数 
    baseURL: window.__baseUrl__,
    //设置请求时间（5S）
    timeout: 800000
})

const redirectLogin = () => {
    const forword = window.location.href.split("#")[1]
    // && forword.indexOf("login") === -1
    if (forword != '/') {
        // 防止重复跳转到登录页面
        router.replace({
            // path: "/login",
            // query: {
            //     forword: forword
            // }
            name: "Home",
            params: {
                forword: forword,
                showLogin: true
            }

        })
    }
    // eventBus.$emit('showLogin', { showLogin: true, forword: forword })
    localStorage.setItem("token", {})
    // }
}


//请求拦截器:在发请求之前可以检测到，可以干一些事情
// instance.interceptors.request.use((config) => {
//     //通过上行请求的请求头，把用户临时身份带给服务器
//     //请求头添加的属性名字不能瞎写，因为已经和后台人员商量好了
//     //如果不叫做userTempid字段，服务器获取不到
//     //config配置对象，这里面有请求头
//     //config，请求拦截器的一个配置对象

//     // if (store.state.shopcart.userTempId) {
//     //     config.headers.userTempId = store.state.shopcart.userTempId
//     // }
//     //用户身份token

//     // if (store.state.user.token) {
//     //     //请求头的这个属性token，和后台老师商量好的了，不能叫做别的名字
//     //     config.headers.token = store.state.user.token
//     // }
//     //检测到程序发请求，请求拦截器可以检测到，进度条开始动
//     nprogress.start()
//     return config
// })

// //响应拦截器：服务器的数据已经返回了，可以干一些事情
// instance.interceptors.response.use((res) => {
//     //简化服务器返回的数据格式
//     //服务器数据返回进度条结束
//     nprogress.done()
//     return res.data
// }, (error) => {
//     //终止promise链
//     return Promise.reject(error)
// })


instance.interceptors.request.use(
    config => {
        nprogress.start()
        // if (config.token) {

        let token = localStorage.token
        // if (!token) {
        //   redirectLogin()
        // }
        if (token) {
            config.headers.Authorization = token
        }
        // config.headers.Authorization = token;
        // }
        return config
    },
    error => {
        // Do something with request error
        message.error("出错了")
        Promise.reject(error)
    }
)
// response 拦截器
instance.interceptors.response.use(
    response => {
        nprogress.done()
        if (router.history.current.meta.isweb && response.data.code == 401) {
            redirectLogin()
        }
        /**
         * code为非20000是抛错 可结合自己业务进行修改
         */
        // if (response.data.code != 200) {
        //     return Promise.reject('dfdddddd')
        //     // return Promise.reject(response.data.msg)
        //     //console.log(response, 'response')
        // }
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)
//对外暴露二次封装的axios
export default instance