<template>
  <div>
    <iframe
      id="qrcode"
      sandbox="allow-scripts allow-top-navigation allow-same-origin"
      scrolling="no"
      width="150"
      height="200"
      frameBorder="0"
      allowTransparency="true"
      :src="setSrc"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "wxLoginCom",
  data () {
    return {
      // redirect_uri: encodeURIComponent(window.location.href)
      redirect_uri: encodeURIComponent("https://unodostech.com/#/")
    }
  },
  computed: {
    setSrc () {
      var _url = 'https://open.weixin.qq.com/connect/qrconnect?appid=' + process.env.VUE_APP_WX_APPID
        + '&scope=' + this.scope
        + '&redirect_uri=' + this.redirect_uri
        + '&state=' + this.state
        + '&login_type=' + this.login_type
        + '&style=' + this.theme
        + '&self_redirect=' + this.self_redirect
        + '&href=' + this.href
      // + '&cardNo=' + this.cardNo
      // console.log(_url, '_url')
      return _url
    },
  },
  props: {
    //应用唯一标识，在微信开放平台提交应用审核通过后获得
    // appid: String,
    //应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
    scope: String,
    //重定向地址，需要进行UrlEncode
    // redirect_uri: String,
    //用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
    state: {
      type: String,
      default: ''
    },
    //提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
    theme: {
      type: String,
      default: 'black'
    },
    // 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
    href: {
      type: String,
      default: ''
    },
    // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
    self_redirect: {
      type: String,
      default: 'default'
    },
    // sdk的扩展字符串，但是在这里就默认了jssdk，暂时不建议修改
    login_type: {
      type: String,
      default: 'jssdk'
    },
    // cardNo: String
  },
}
</script>
