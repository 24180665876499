<template>
  <div>
    <el-dialog
      title="修改资料"
      :visible.sync="isShowUserBox"
      :show-close="false"
      :modal-append-to-body="false"
      :before-close="handleClose"
    >
      <div class="dialog-footer">
        <el-button v-show="isChange" type="info" @click="handleClose"
          >取 消</el-button
        >
        <el-button type="info" v-show="isChange" @click="editUserInfo"
          >确 定</el-button
        >
        <el-button type="info" v-show="!isChange" @click="isChange = true"
          >修改</el-button
        >
      </div>
      <!-- <div class="head"><img :src="userInfo.avatar" alt="" /></div> -->
      <el-upload
        class="userInfo.avatar?'avatar-uploader':avatarborder avatar-uploader"
        :action="baseUrl + '/business/user/updateAvatar'"
        :show-file-list="false"
        :headers="{
          Authorization: gettoken,
        }"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        name="avatarFile"
      >
        <img
          :src="userInfo.avatar || require('../images/head.png')"
          class="avatar"
        />
        <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
      </el-upload>
      <el-form :inline="true" :model="userInfo" class="demo-form-inline">
        <el-form-item>
          <p class="font16400 grey1">昵称</p>
          <el-input
            :disabled="!isChange"
            v-model="userInfo.nickName"
            suffix-icon="el-icon-edit"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <p class="font16400 grey1">性别</p>
          <el-select v-model="userInfo.sex" :disabled="!isChange">
            <el-option label="男" value="0"></el-option>
            <el-option label="女" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <p class="font16400 grey1">身份</p>
          <el-select v-model="userInfo.career" :disabled="!isChange">
            <el-option value="0" label="学生"></el-option>
            <el-option value="1" label="在职"></el-option>
            <el-option value="2" label="教师"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <p class="font16400 grey1">学校</p>
          <!-- <el-select v-model="userInfo.schoolId" :disabled="!isChange">
            <el-option
              :label="school.schoolName"
              :value="school.id"
              v-for="school in schoolList"
              :key="school.id"
            ></el-option>
          </el-select> -->
          <el-autocomplete
            v-model="userInfo.school"
            :fetch-suggestions="querySearch"
          >
            <i slot="suffix" class="el-input__icon el-icon-warning-outline"></i>
            <template slot-scope="{ item }">
              <div style="display: flex">
                <div
                  style="
                    width: 16px;
                    height: 16px;
                    margin-top: -2px;
                    margin-right: 5px;
                  "
                >
                  <img src="../images/zhuyi.png" alt="" />
                </div>
                <span class="font16400 grey1">{{ item.tishi }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <p class="font16400 grey1">手机号</p>
          <el-input v-model="userInfo.mobile" :disabled="true">
            <span
              slot="suffix"
              class="font12400 red1 cursorpointer"
              @click="changemobile(true)"
              >修改</span
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <p class="font16400 grey1">邀请码</p>
          <el-input v-model="userInfo.inviteCode" :disabled="true">
            <i
              class="el-icon-copy-document pointer"
              slot="suffix"
              @click="copyText"
            >
            </i
          ></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <ChangeMobile :dialogshow="dialogChangeMobile"></ChangeMobile>
  </div>
</template>

<script>
import {
  getUserInfo, getSchoolList, updateBaseInfo,
} from "@/api"
import ChangeMobile from "../changemobile"
export default {
  components: {
    ChangeMobile,
  },
  data () {
    return {
      baseUrl: window.__baseUrl__,
      // dialogVisible: false,
      userInfo: {},
      // formInline: {
      //   name: '',
      //   region: '',
      //   date1: '',
      //   date2: '',
      //   delivery: false,
      //   type: [],
      //   resource: '',
      //   desc: ''
      // },
      formLabelWidth: '120px',
      schoolList: [],
      isChange: false,
      dialogChangeMobile: false,
    }
  },
  computed: {
    gettoken () {
      return localStorage.getItem('token')
    }
  },
  mounted () {

    // this.getUserInfo()
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {}
    // this.getSchoolList()

    this.$bus.$on("doChangeMobile", val => {

      this.changemobile(val)
    })
  },
  props: ['isShowUserBox'],
  methods: {
    handleClose () {
      this.isChange = false
      this.$bus.$emit("doChangeUserShow", false)
    },
    querySearch (queryString, cb) {
      var results = [{ tishi: '请在APP内进行修改' }]
      cb(results)
    },
    changemobile (val) {
      this.dialogChangeMobile = val
    },
    copyText () {
      navigator.clipboard.writeText(this.userInfo.inviteCode).then(() => {
        this.$message.success('复制成功')
      })


    },
    editUserInfo () {
      let param = { 'nickName': this.userInfo.nickName, 'sex': this.userInfo.sex, 'career': this.userInfo.career, 'schoolId': this.userInfo.schoolId }
      updateBaseInfo(param).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功")
          this.isChange = false
          // this.dialogVisible = false
          this.$bus.$emit("doChangeUserShow", false)
          this.$bus.$emit("getUserInfo1")
        } else {
          this.$message.error(res.msg)
        }
      })

    },
    beforeAvatarUpload (file) {
      // console.log(file, 'file')
      let geshi = ["image/jpg", "image/png", "image/jpeg"]
      // const isJPG = file.type === 'image/jpeg'
      // const isJPG = file.type == "image/jpg"
      // const isPng = file.type == "image/png"
      // const isJpeg = file.type == "image/jpeg"
      const isJPG = geshi.indexOf(file.type) != -1
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 jpg/png/jpeg 格式!')
        return false
      }

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        return false
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess (res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw)
      // this.userInfo.avatar
      // console.log(res, file, '/business/user/updateAvatar')
      if (res.code == 200) {
        //
        this.$bus.$emit("getUserInfo1")
        this.getUserInfo()

        this.$message.success("上传成功")

      } else {
        this.$message.error("上传失败")
      }
    },

    getUserInfo () {
      getUserInfo().then(d => {
        if (d.code == 200) {
          this.userInfo = d.user
          this.userInfo.avatar = (this.userInfo.avatar.indexOf('http') == -1) ? this.baseUrl + this.userInfo.avatar : this.userInfo.avatar
          this.userInfo.mobile = this.userInfo.mobile.replace(/(\d{2})(\d{3})\d{4}(\d{4})/, "$2****$3")

        }

      })
    },
    getSchoolList () {
      getSchoolList().then(d => {
        console.log(d)
        this.schoolList = d.data

      })
    },
  },
  // watch: {
  //   "isShowUserBox" (newVal, oldVal) {
  //     this.dialogVisible = newVal
  //   }
  // }
}
</script>

<style lang="less" scoped>
.pointer {
  cursor: pointer;
}
// /deep/.el-select-dropdown__item.selected {
//   color: red;
// }
/deep/.el-input.is-disabled .el-input__inner {
  background-color: white;
}
/deep/.el-input__inner {
  color: #000;
}
/deep/.el-upload {
  width: 96px;
  height: 96px;

  img {
    border-radius: 50%;
  }
}
/deep/.avatar-uploader {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}
/deep/.el-input__inner:focus {
  border-color: rgba(67, 207, 124, 1);
}
/deep/.el-select .el-input.is-focus .el-input__inner {
  border-color: rgba(67, 207, 124, 1);
}
/deep/.el-dialog__header {
  padding-top: 30px;
}
/deep/.el-input {
  width: 240px;
  margin-top: 14px;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}
/deep/.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/deep/.el-dialog {
  padding: 0px 30px;
  width: 660px;
  height: 544px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);

  border: 2px solid rgba(255, 255, 255, 1);

  backdrop-filter: blur(20px);
}
/deep/.el-dialog__body {
  padding: 0 20px;
}
/deep/.dialog-footer {
  position: absolute;
  right: 50px;
  top: 30px;
}
/deep/.el-button {
  width: 74px;
  height: 36px;
  line-height: 36px;
  padding: 0;
  opacity: 1;
  border-radius: 8px;
  // background: rgba(245, 245, 247, 1);

  font-size: 18px;
  font-weight: 500;
  // color: rgba(67, 207, 124, 1);
  border: none;
}
/deep/.activebutton {
  background: linear-gradient(
    135deg,
    rgba(94, 205, 143, 1) 0%,
    rgba(103, 224, 168, 1) 100%
  );
  color: rgba(255, 255, 255, 1);
}

/deep/.el-button--info {
  background-color: rgba(245, 245, 247, 1);
  color: rgba(67, 207, 124, 1);
}
/deep/.el-button:hover {
  background: linear-gradient(
    135deg,
    rgba(94, 205, 143, 1) 0%,
    rgba(103, 224, 168, 1) 100%
  );
  color: #fff;
}
/deep/.el-button {
  border: none;
}
/deep/.el-form-item {
  margin-top: 10px;
}
</style>