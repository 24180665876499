<template>
  <div>
    <div class="qrcodepay" ref="qrcodepay" v-show="payType == 'WXPAY'"></div>
    <iframe
      ref="aliqrcodepay"
      v-show="payType == 'ALIPAY'"
      :srcdoc="link"
      frameborder="no"
      border="0"
      marginwidth="0"
      marginheight="0"
      scrolling="no"
      width="100"
      height="100"
      style="overflow: hidden"
    >
    </iframe>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {
  orderDetail,
  getImgCode,
} from "@/api"
export default {
  name: "pay",
  props: {
    centerDialogVisible: false,
    orderId: 0,
    payType: '',
    // key1: ''
  },
  data () {
    return {
      baseUrl: window.__baseUrl__,
      timer: null,
      code: 0,
      orderDetail: {},
      link: '',
    }
  },
  mounted () {

    this.getImgCode()

    this.$bus.$on("doCloseCode", (val) => {
      this.closeCodeBus(val)
    })

  },
  methods: {
    getOrderDetail (orderId) {
      orderDetail(orderId).then((d) => {
        if (d.code == 200) {
          this.orderDetail = d.data
        } else {
          this.$message.error(d.msg)
        }
      })
    },
    getImgCode () {

      getImgCode({ orderId: this.orderId, payFrom: "web", payType: this.payType }).then((dd) => {
        // console.log(dd, 'zhifu')
        // this.link = "weixin://wxpay/bizpayurl?pr=sODcWp7zz"
        this.link = dd.prepay

        this.qrcodepay(110, 110, this.link, 'canvas')
        if (this.timer == null) {
          this.timer = setInterval(() => {
            // this.getOrderDetail(this.orderId)
            orderDetail(this.orderId).then((d) => {
              if (d.code == 200) {
                this.orderDetail = d.data
                if (this.orderDetail.payStatus == 1) {
                  clearInterval(this.timer)
                  this.timer = null
                  this.code = 200
                  this.$message({
                    message: '支付成功',
                    type: 'success'
                  })
                  // 关闭弹出框
                  this.closeCode()
                  this.$bus.$emit("getUserInfo1")
                  // this.$router.push({ path: '/uc' })
                }
              } else {

                this.closeCode()
                if (d.code == 401) {
                  this.$bus.$emit('showLogin', { showLogin: true, forword: '' })
                }
                this.$message.error(d.msg)
              }
            })

          }, 1000)
        }
      })


    },
    qrcodepay (qWidth, qHeight, qText, qRender) {
      if (this.payType == "WXPAY") {
        let el = this.$refs.qrcodepay
        new QRCode(el, {
          width: qWidth,
          height: qHeight,
          text: qText,
          render: qRender,
          correctLevel: QRCode.CorrectLevel.M
        })
      }
      // else if (this.payType == "ALIPAY") {
      //   //支付宝付款
      //   //拼接url  其中this.https.urls是后端给接口地址，，其他为参数
      //   let myurl = window.__baseUrl__ + "/index/memory/familytree_pay?user_id" + localStorage.getItem("userInfo").userId + "&order=" + this.orderId
      //   //直接跳转
      //   window.location.href = myurl

      // }
    },
    // 关闭弹框,清除已经生成的二维码
    closeCode () {
      // this.dialogVisible = false
      clearInterval(this.timer)
      this.timer = null
      // this.$bus.$emit("buyChangeVisible", false)
      this.$bus.$emit("doShowBuy", false)
      this.$refs.qrcodepay.innerHTML = ""
      this.$refs.aliqrcodepay.innerHTML = ""
    },
    closeCodeBus () {
      // this.dialogVisible = false
      clearInterval(this.timer)
      this.timer = null
      this.$refs.qrcodepay.innerHTML = ""
      this.$refs.aliqrcodepay.innerHTML = ""
    },
    // handleClose (done) {

    //   // 判断是否真的支付了
    //   if (this.code == 200) {
    //     console.log("支付成功啦")

    //   }
    //   clearInterval(this.timer)
    //   this.timer = null
    //   this.$emit("changeVisible", false)
    //   // done()

    //   this.$refs.qrcode.innerHTML = ""
    // }
  },
  watch: {
    orderId: {
      handler (newVal, oldVal) {
        if (newVal) {
          console.log(newVal, 'newValorder')
          clearInterval(this.timer)
          this.timer = null
          this.$refs.qrcodepay.innerHTML = ""
          this.$refs.aliqrcodepay.innerHTML = ""
          this.getImgCode()
        }

      },
    },
    payType: {
      handler (newVal, oldVal) {
        // console.log(newVal, 'newValpay')
        clearInterval(this.timer)
        this.timer = null
        this.$refs.qrcodepay.innerHTML = ""
        this.$refs.aliqrcodepay.innerHTML = ""

        this.getImgCode()
      },
    }

  },
  beforeDestroy () {
    this.$bus.$off("doCloseCode")
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog--center .el-dialog__body,
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-dialog {
  border-radius: 10px;
}
canvas {
  width: 100px;
  height: 100px;
}
p,
div {
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
  height: 100%;
}
.ver {
  writing-mode: vertical-rl;
}
.textright {
  text-align: right;
}
.marginTop {
  margin-top: 76px;
}
.buydetail {
  display: flex;
  justify-content: space-between;
  .kaitem {
    background-color: #ebeef5;
  }
  .qrcodepay {
    width: 180px;
    height: 180px;
    border: 1px solid #00c800;
    padding: 9px;
  }
  .wxpay {
    display: flex;
    align-items: center;
    img {
      width: 28px;
      height: 28px;
      margin-right: 4px;
    }
    p {
      font-family: "FangZheng-FanTi";
      font-size: 16px;
      font-weight: 400;
      color: #959595;
    }
  }
  .bgimg {
    height: 536px;
    width: auto;
  }
  .rightdetail {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 38px 16px 50px 0;
    .cuohao {
      width: 18px;
      height: 18px;
      // margin-right: 10px;
    }
    .tongyi {
      font-family: "FangZheng-FanTi";
      font-size: 19px;
      font-weight: 400;
      color: #959595;
    }
  }
}
.kaitem {
  width: 293px;
  height: 178px;
  background: #ffffff;
  box-shadow: 0px 3px 8px 2px rgba(125, 125, 125, 0.3);
  border-radius: 10px;
  color: #344672;
  display: flex;
  justify-content: space-between;

  overflow: hidden;
  .katitle {
    padding: 20px 0;
    font-size: 130px;
    font-weight: bold;
    margin-left: -26px;
    color: #1c3d85;
  }
  .kayue {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .kastart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .kaend {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .kacontent {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .kades {
    font-size: 27px;
    font-weight: bold;
    color: #cc9933;
  }
}
</style>