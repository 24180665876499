<template>
  <div class="footer">
    <p>
      北京吾诺道思信息科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;邮箱：unodospie@126.com&nbsp;&nbsp;&nbsp;&nbsp;地址：北京市海淀区大钟寺东路9号1幢B座1层119-153
    </p>
    <p>
      Copyright ©️
      <a target="_blank" href="https://beian.miit.gov.cn/"
        >京ICP备2022018371号 -1</a
      >&nbsp;&nbsp;&nbsp;&nbsp;互联网信息服务许可证：京B2-20224027
      &nbsp;&nbsp;&nbsp;&nbsp;出版物经营许可：新出发京零字第海220967号
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"

}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 80px;
  z-index: 8;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #fff;
  text-align: center;
  padding: 20px 0;

  /** 文本1 */
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18.56px;
    color: rgba(119, 119, 119, 1);
  }
  a {
    color: rgba(119, 119, 119, 1);
  }
  a:hover {
    color: rgba(67, 207, 124, 1);
  }
}
</style>