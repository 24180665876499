<template>
  <el-dialog
    :visible.sync="dialogshow"
    width="660px"
    :modal-append-to-body="false"
    :before-close="handleClose"
  >
    <div slot="title" class="title">
      <img src="../images/left.png" alt="" /><span class="font20500 black2"
        >修改资料</span
      >
    </div>
    <div class="dialogcontent" v-if="firstStep">
      <p class="font20500 black2 lineheight3">修改手机号</p>
      <p class="font16400 grey1 lineheight2">
        解绑后将无法使用该手机号登录此账号！
      </p>
      <p class="font16400 grey1 lineheight2">
        当前手机号：{{ userInfo.mobilep }}
      </p>

      <el-input
        placeholder="输入验证码"
        style="width: 220px; height: 48px; margin-right: 8px"
        v-model="changemobile.smsCode"
      ></el-input
      ><el-button
        @click.prevent="getSMSNew(changemobile.mobile, 'CHANGE')"
        style="
          width: 112px;
          color: #000;
          font-size: 18px;
          height: 48px;
          border: 1px solid rgba(238, 238, 238, 1);
          background: none;
        "
        >{{ changemobile.text }}</el-button
      >
    </div>
    <span slot="footer" v-if="firstStep">
      <el-button type="info" @click="handleClose">取 消</el-button>
      <el-button type="info" @click="getSecondStep">下一步</el-button>
    </span>
    <div class="dialogcontent" v-if="!firstStep">
      <p class="font20500 black2 lineheight3">绑定新手机号</p>
      <el-input
        class="mobileclass"
        style="width: 340px; height: 48px"
        placeholder="请输入手机号"
        v-model="changemobile.newmobile"
      >
        <template slot="prepend">+86</template>
      </el-input>

      <el-input
        placeholder="输入验证码"
        style="width: 220px; height: 48px; margin-right: 8px"
        v-model="changemobile.smsCode"
      ></el-input
      ><el-button
        @click.prevent="getSMSNew(ac + changemobile.newmobile, 'CHANGE_NEW')"
        style="
          width: 112px;
          color: #000;
          font-size: 18px;
          height: 48px;
          border: 1px solid rgba(238, 238, 238, 1);
          background: none;
        "
        >{{ changemobile.text }}</el-button
      >
    </div>

    <span slot="footer" v-if="!firstStep">
      <el-button type="info" @click="handleClose">取 消</el-button>
      <el-button type="info" @click="changemobileByUser()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getUserInfo, smsCode, verifyOldCode, changeMobile
} from "@/api"
export default {

  name: "ChangeMobile",
  data () {
    return {
      // dialogChangeMobile: false,
      userInfo: {},
      firstStep: true,
      ac: '86',
      changemobile: {
        mobile: "",
        newmobile: "",
        smsCode: "",
        text: '获取验证码',
        timer: null,
        uuid: ""
      },
    }
  },
  props: ['dialogshow'],
  mounted () {
    // this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    this.getUserInfo()
  },
  methods: {
    handleClose () {
      this.$bus.$emit("doChangeMobile", false)
    },
    getSecondStep () {
      let parmobj = {
        mobile: this.changemobile.mobile,
        uuid: this.changemobile.uuid,
        smsCode: this.changemobile.smsCode
      }
      if (!this.changemobile.smsCode) {
        this.$message.error("请填写验证码")
        return
      }
      verifyOldCode(parmobj).then(res => {
        if (res.code == 200) {
          this.firstStep = false
          this.changemobile.smsCode = ''
          this.changemobile.newmobile = ''
          this.changemobile.uuid = ''
          this.changemobile.text = '获取验证码'
          clearInterval(this.changemobile.timer)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    changemobileByUser () {
      if (!/^[1][35789][0-9]{9}$/.test(this.changemobile.newmobile)) {
        this.$message.error("请输入正确手机号")
        return
      }
      let parmobj = {
        mobile: this.ac + this.changemobile.newmobile,
        uuid: this.changemobile.uuid,
        smsCode: this.changemobile.smsCode,
        scene: "CHANGE_NEW"
      }
      changeMobile(parmobj).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          // this.dialogChangeMobile = false
          this.$bus.$emit("doChangeMobile", false)
          this.firstStep = true
          this.changemobile.smsCode = ''
          this.changemobile.uuid = ''
          this.changemobile.text = '获取验证码'
          clearInterval(this.changemobile.timer)

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getSMSNew (mobile, bind) {
      // "BIND"
      // let usemobile = mobile || this.userInfo.mobile
      // if (!/^86[1][35789][0-9]{9}$/.test(mobile)) {
      if (!/^[0-9]*$/.test(mobile)) {
        this.$message.error("请输入正确手机号")
        return
      }

      if (this.changemobile.text === "获取验证码") {
        smsCode({
          mobile: mobile,
          scene: bind
        }).then(res => {
          if (res.code == 500) {
            this.$message.error(res.msg)
            return
          }
          let num = 60
          this.changemobile.timer = setInterval(() => {
            num--

            if (num === 0) {
              clearInterval(this.changemobile.timer)
              this.changemobile.timer = null
              this.changemobile.text = `获取验证码`
            }
            else {
              this.changemobile.text = `重新获取(${num}s)`
              // console.log(num,'num');
            }
          }, 1000)

          this.changemobile.uuid = res.uuid
          // this.form.smsCode = res.smsCode
        })
      } else {
      }
    },
    getUserInfo () {
      getUserInfo().then(d => {
        if (d.code == 200) {
          this.userInfo = d.user
          this.changemobile.mobile = this.userInfo.mobile
          // this.userInfo.avatar = (this.userInfo.avatar.indexOf('http') == -1) ? this.baseUrl + this.userInfo.avatar : this.userInfo.avatar
          this.userInfo.mobilep = this.userInfo.mobile.replace(/(\d{2})(\d{3})\d{4}(\d{4})/, "$2****$3")

        }

      })
    },
  },
  // watch: {
  //   "dialogshow" (newVal, oldVal) {
  //     this.dialogChangeMobile = newVal
  //   }
  // }

}
</script>

<style  lang="less" scoped>
@import url(../../../assets/css/common.css);
// /deep/.el-button--primary {
//   background: linear-gradient(
//     135deg,
//     rgba(94, 205, 143, 1) 0%,
//     rgba(103, 224, 168, 1) 100%
//   );
//   color: #fff;
// }
/deep/.el-input-group__prepend {
  border-radius: 8px 0px 0px 8px;
  background: rgba(245, 245, 247, 1);
  width: 61px;
  height: 48px;
  color: rgba(29, 29, 31, 1);
  font-size: 16px;
  font-weight: 400;
}
.mobileclass {
  /deep/.el-input__inner {
    border-radius: 0px 8px 8px 0px;
  }
}

/deep/.el-input__inner {
  height: 48px;
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
  color: rgba(29, 29, 31, 1);
  font-size: 16px;
  font-weight: 400;
}
/deep/.tel .el-input__inner {
  border-radius: 0 8px 8px 0;
}
/deep/.el-button--info {
  background-color: rgba(245, 245, 247, 1);
  color: rgba(67, 207, 124, 1);
  border: none;
}
/deep/.el-button:hover {
  background: linear-gradient(
    135deg,
    rgba(94, 205, 143, 1) 0%,
    rgba(103, 224, 168, 1) 100%
  );
  color: #fff;
}
/deep/.dialogcontent {
  padding: 90px 100px;
}
/deep/.dialog-footer {
  position: static;
}
/deep/.el-input__inner {
  height: 48px;
  font-size: 18px;
}
.title {
  box-sizing: border-box;
  display: flex;
}
.title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  // margin-top: 16px;
}
/deep/.el-button {
}
</style>