<template>
  <div :class="isfloat ? 'header fix' : 'header'">
    <div class="w headnav">
      <div class="bignav">
        <div class="logo">
          <router-link to="/" style="text-decoration=none"
            ><img src="./images/logo.png" alt="" />
            <p>西语派学道</p></router-link
          >
        </div>
        <div class="nav">
          <ul>
            <li class="navitem" v-for="nav in navlist" :key="nav.id">
              <router-link
                :class="nav.index == $route.meta.index ? 'active' : ''"
                :to="nav.path"
                >{{ nav.title }}</router-link
              ><img
                v-if="nav.isImg"
                src="./images/zuanshi.png"
                alt=""
                style="margin-left: 4px"
              />
            </li>
            <!-- <li class="navitem"><a class="active" href="">词典·语料库</a></li>
          <li class="navitem">
            <router-link to="/course">西语课程</router-link>
          </li>
          <li class="navitem">
            <router-link to="/partner">语伴&nbsp;</router-link
            ><img src="./images/zuanshi.png" alt="" />
          </li>
          <li class="navitem"><router-link to="/find">发现</router-link></li>
          <li class="navitem">
            <router-link to="/download">移动端下载</router-link>
          </li>
          <li class="navitem">
            <router-link to="/aboutus">关于我们</router-link>
          </li> -->
          </ul>
          <div class="denglu" v-if="!isLogin">
            <a href="" @click.prevent="changeShowLogin(true)">登录/注册</a>
          </div>
          <div class="denglu" v-if="isLogin">
            <div class="head">
              <img
                :src="userInfo.avatar || require('./images/head.png')"
                alt=""
              />
            </div>
            <!-- <div class="down" @click="changeuservisible">
              <img v-if="uservisible" src="./images/down.png" alt="" />
              <img v-else src="./images/d.png" alt="" />
            </div> -->
            <!-- trigger="click" -->
            <el-dropdown @visible-change="changeuservisible">
              <span class="el-dropdown-link">
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                <img
                  style="margin-left: 5px; width: 24px; height: 24px"
                  v-if="uservisible"
                  src="./images/down.png"
                  alt=""
                />
                <img
                  style="margin-left: 5px; width: 24px; height: 24px"
                  v-else
                  src="./images/d.png"
                  alt=""
                />
              </span>
              <el-dropdown-menu
                slot="dropdown"
                class="navdropdown loginInfo font14400 black1"
              >
                <el-dropdown-item
                  ><div class="infoOne" @click="changeUserShow(true)">
                    <div class="infohead">
                      <img
                        :src="userInfo.avatar || require('./images/head.png')"
                        alt=""
                      />
                    </div>
                    <div class="infotwo">
                      <p class="font14400 black1">
                        {{ userInfo.nickName }}
                      </p>
                      <p class="font12400 grey1">
                        {{ userInfo.mobile }}
                      </p>
                    </div>
                    <div class="edit">
                      <img src="./images/edit.png" alt="" />
                    </div></div
                ></el-dropdown-item>
                <el-dropdown-item
                  ><div
                    class="member cursorpointer"
                    v-if="!isVip"
                    @click="showBuy(true)"
                  >
                    <div class="memberitem general">
                      <div class="front" style="line-height: 20px">
                        <p class="font14500">普通会员</p>
                        <p class="font12400 grey1">开通会员享受更多服务</p>
                      </div>
                      <div class="end">
                        <p class="font12500 color2">去开通</p>
                        <img src="./images/down2.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="member cursorpointer"
                    v-if="isVip"
                    @click="showBuy(true)"
                  >
                    <div class="memberitem gold"></div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="#" @click="suggest(true)"
                    >建议反馈</a
                  ></el-dropdown-item
                >
                <el-dropdown-item
                  ><a href="#" @click.stop="getout"
                    >退出登录</a
                  ></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="smallnav">
        <!-- <div class="hanbclose" v-if="showdown">
          <img src="./images/close.png" alt="" />
        </div> -->

        <el-dropdown placement="bottom-start" @visible-change="handlechange">
          <span class="el-dropdown-link">
            <img v-if="showdown" src="./images/close.png" alt="" />
            <img v-else src="./images/hanb.png" alt="" />
          </span>
          <el-dropdown-menu
            slot="dropdown"
            style="
              padding: 10px 20px;
              border: 2px solid rgba(255, 255, 255, 1);
              border-radius: 8px;
              margin-top: 0px;
            "
          >
            <el-dropdown-item
              class="navitem"
              v-for="nav in navlist"
              :key="nav.id"
            >
              <router-link
                :class="nav.index == $route.meta.index ? 'active' : ''"
                :to="nav.path"
                >{{ nav.title
                }}<img
                  v-if="nav.isImg"
                  src="./images/zuanshi.png"
                  alt=""
                  style="margin-left: 4px; width: 20.08px; height: 18px"
              /></router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="logo">
          <a href="./" style="text-decoration=none"> <p>西语派学道</p></a>
        </div>
        <div class="denglu" v-if="!isLogin">
          <a href="" @click.prevent="changeShowLogin(true)">登录/注册</a>
        </div>
        <div class="denglu" v-if="isLogin">
          <div class="head">
            <img
              :src="userInfo.avatar || require('./images/head.png')"
              alt=""
            />
          </div>
          <el-dropdown @visible-change="changeuservisible">
            <span class="el-dropdown-link">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              <img
                style="margin-left: 5px; width: 24px; height: 24px"
                v-if="uservisible"
                src="./images/down.png"
                alt=""
              />
              <img
                style="margin-left: 5px; width: 24px; height: 24px"
                v-else
                src="./images/d.png"
                alt=""
              />
            </span>
            <el-dropdown-menu
              slot="dropdown"
              class="navdropdown loginInfo font14400 black1"
            >
              <el-dropdown-item
                ><div class="infoOne" @click="changeUserShow(true)">
                  <div class="infohead">
                    <img
                      :src="userInfo.avatar || require('./images/head.png')"
                      alt=""
                    />
                  </div>
                  <div class="infotwo">
                    <p class="font14400 black1">
                      {{ userInfo.nickName }}
                    </p>
                    <p class="font12400 grey1">
                      {{ userInfo.mobile }}
                    </p>
                  </div>
                  <div class="edit">
                    <img src="./images/edit.png" alt="" />
                  </div></div
              ></el-dropdown-item>
              <el-dropdown-item
                ><div
                  class="member cursorpointer"
                  v-if="!isVip"
                  @click="showBuy(true)"
                >
                  <div class="memberitem general">
                    <div class="front" style="line-height: 20px">
                      <p class="font14500">普通会员</p>
                      <p class="font12400 grey1">开通会员享受更多服务</p>
                    </div>
                    <div class="end">
                      <p class="font12500 color2">去开通</p>
                      <img src="./images/down2.png" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  class="member cursorpointer"
                  v-if="isVip"
                  @click="showBuy(true)"
                >
                  <div class="memberitem gold"></div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="#" @click="suggest(true)"
                  >建议反馈</a
                ></el-dropdown-item
              >
              <el-dropdown-item
                ><a href="#" @click.stop="getout">退出登录</a></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <!-- <div class="loginInfo font14400 black1" v-show="uservisible">
        <div class="infoOne">
          <div class="infohead"><img :src="userInfo.avatar" alt="" /></div>
          <div class="infotwo">
            <p class="">
              {{ userInfo.nickName }}
            </p>
            <p class="font12400 grey1">
              {{ userInfo.mobile }}
            </p>
          </div>
          <div class="edit" @click="changeUserShow">
            <img src="./images/edit.png" alt="" />
          </div>
        </div>
        <div class="member cursorpointer" v-if="!isVip" @click="showBuy">
          <div class="memberitem general">
            <div class="front">
              <p class="font14500">普通会员</p>
              <p class="font12400 grey1">开通会员享受更多服务</p>
            </div>
            <div class="end">
              <p class="font12500 color2">去开通</p>
              <img src="./images/down2.png" alt="" />
            </div>
          </div>
        </div>
        <div class="member cursorpointer" v-if="isVip" @click="showBuy">
          <div class="memberitem gold"> -->
      <!-- <div class="front">
              <p class="font14500">黄金会员</p>
              <p class="font12400 grey1">黄金会员享受专属服务</p>
            </div>
            <div class="end">
              <p class="font12500 color2">去续费</p>
              <img src="./images/down2.png" alt="" />
            </div> -->
      <!-- </div>
        </div>
        <div class="infothree">
          <p @click="suggest">建议反馈</p>
          <p @click.stop="getout">退出登录</p>
        </div>
      </div> -->
    </div>
    <Login
      :loginDialog="loginDialog"
      :forword="forword"
      v-show="loginDialog"
    ></Login>
    <Buy :isShowBuy="isShowBuy" v-if="isShowBuy"></Buy>
    <Userinfo :isShowUserBox="isShowUserBox" v-if="isShowUserBox"></Userinfo>
    <Suggest :isShowSuggest="isShowSuggest" v-if="isShowSuggest"></Suggest>
  </div>
</template>

<script>
import {
  getUserInfo,
} from "@/api"
import Buy from "./Buy"
import Userinfo from "./userinfo"
import Suggest from "./suggest"
import Login from "../../components/Login"
export default {

  name: 'Header',
  components: {
    Buy,
    Userinfo,
    Login,
    Suggest
  },
  data () {
    return {
      forword: "",
      baseUrl: window.__baseUrl__,
      isfloat: false,//导航是否浮动
      userInfo: {},
      uservisible: false,
      isLogin: false,
      loginDialog: false,
      isShowBuy: false,
      isShowUserBox: false,
      isShowSuggest: false,
      isVip: false,
      activenav: 0,
      navlist: [
        { path: '/search', title: '词典·语料库', id: 1, isImg: false, index: 3 },
        { path: '/course', title: '西语课程', id: 2, isImg: false, index: 4 },
        { path: '/partner', title: '语伴', id: 3, isImg: true, index: 6 },
        { path: '/find', title: '发现', id: 4, isImg: false, index: 5 },
        { path: '/download', title: '移动端下载', id: 5, isImg: false, index: 2 },
        { path: '/aboutus', title: '关于我们', id: 6, isImg: false, index: 1 }
      ],
      showdown: false
    }
  },
  mounted () {
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {}


    // this.getUserInfo()
    this.isnotVip()
    window.addEventListener("scroll", this.menufloat, { passive: true })
    this.$bus.$on("getUserInfo1", () => {
      this.getUserInfo()
    })
    this.$bus.$on('showLogin', (val) => {
      this.loginDialog = val.showLogin
      this.forword = val.forword
    })
    this.$bus.$on("outLogin", type => {
      this.outLogin()
    })
    // this.$bus.$on("changeShowLogin", val => {
    //   this.changeShowLogin(val)
    // })

    this.$bus.$on("doShowBuy", val => {

      this.showBuy(val)
    })

    this.$bus.$on("doSuggest", val => {

      this.suggest(val)
    })

    this.$bus.$on("doChangeUserShow", val => {

      this.changeUserShow(val)
    })
    // if (localStorage.token) {
    //   this.getUserInfo()
    // }

    this.outLogin()
  },
  methods: {
    handlechange (val) {
      this.showdown = val
    },
    isnotVip () {
      let nowtimestamp = new Date().getTime()
      if (this.userInfo.vipValidityDate) {
        let viptime = Date.parse(this.userInfo.vipValidityDate)
        this.isVip = viptime > nowtimestamp
      }
    },
    suggest (val) {
      this.isShowSuggest = val
      this.uservisible = false
    },
    showBuy (val = true) {
      console.log(val, 'val')
      this.isShowBuy = val
      this.uservisible = false
    },
    getout () {
      localStorage.removeItem("token")
      localStorage.removeItem("userInfo")
      this.$router.replace({
        path: "/"
      })
      this.uservisible = false
      this.isLogin = false
      this.uservisible = false
      this.userInfo = {}
    },
    changeUserShow (val) {
      this.isShowUserBox = val
      this.uservisible = false
    },
    outLogin () {
      this.isLogin = (localStorage.getItem("token") && JSON.stringify(this.userInfo) != '{}') ? true : false
    },
    changeShowLogin (val) {
      // console.log('cccc')
      this.loginDialog = val

    },
    changeuservisible (val) {
      this.uservisible = val
    },
    menufloat () {
      let t = document.documentElement.scrollTop
      if (t >= 50) {
        this.isfloat = true
      } else {
        this.isfloat = false
      }
    },
    getUserInfo () {
      getUserInfo().then(d => {
        if (d.code == 200) {
          this.userInfo = d.user
          // this.userInfo.avatar = require("./images/head.png")
          this.userInfo.avatar = this.userInfo.avatar ? (this.userInfo.avatar.indexOf('http') == -1) ? this.baseUrl + this.userInfo.avatar : this.userInfo.avatar : ""
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))


          this.isLogin = true
          this.isnotVip()
        }

      })
    },
  },
  destroy () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.menufloat)
    this.$bus.$off("outLogin")
    this.$bus.$off("getUserInfo1")
    this.$bus.$off("showLogin")
    this.$bus.$off("doShowBuy")
    this.$bus.$off("doSuggest")
    this.$bus.$off("doChangeUserShow")

  },


}
</script>

<style lang="less" scoped>
.navdropdown {
  /deep/.el-dropdown-menu__item {
    border: none;
  }
}
.navdropdown {
  margin-top: 20px;
  box-sizing: border-box;
}
/deep/.el-dropdown {
  cursor: pointer;
}
.fix {
  position: fixed;
  background-color: #fff;
}
/deep/.el-dropdown-menu__item:hover {
  background: none;
}
/deep/.el-dropdown-menu__item {
  padding: 0;
  border-bottom: 1px solid rgba(238, 238, 238, 1);

  a {
    display: flex;
    align-items: center;
  }
  .active {
    color: rgba(67, 207, 124, 1);
  }
  a:hover {
    text-decoration: none;
  }
}

/deep/.popper__arrow {
  display: none;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.25rem;
  z-index: 99;
  // transition: all 2s ease-in-out;
  transition: all 0.6 ease-in-out;
  // background-color: pink;
  .w {
    // width: 1500px;
    // height: 100%;
    // margin: 0 auto;

    position: relative;
  }

  .bignav {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // display: none;
  }

  .smallnav {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
    .denglu {
      margin-left: 0;
    }
    .logo {
      a {
        width: auto;
        text-decoration: none;
      }
      a:hover {
        text-decoration: none;
      }
    }

    .navitem {
      // display: flex;
      // align-items: center;
      a {
        /** 文本1 */
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 21.22px;
        color: rgba(29, 29, 31, 1);
        &:hover {
          color: rgba(67, 207, 124, 1);
          text-decoration: none;
        }
      }
      .active {
        color: rgba(67, 207, 124, 1);
        text-decoration: none;
      }
    }
  }

  .logo {
    a {
      width: 180px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;

      img {
        width: 40px;
        height: 40px;
      }
      p {
        opacity: 1;
        /** 文本1 */
        font-family: "ql-font-hukang";
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(26, 167, 77, 1);
        text-align: left;
        vertical-align: top;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
  .hanbclose {
    width: 24px;
    height: 24px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ul {
      display: flex;
      justify-content: right;
      align-items: center;
      .navitem {
        margin-left: 50px;
        display: flex;
        align-items: center;
        img {
          width: 20.08px;
          height: 18px;
        }
        a {
          /** 文本1 */
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 21.22px;
          color: rgba(29, 29, 31, 1);
          &:hover {
            color: rgba(67, 207, 124, 1);
            text-decoration: none;
          }
        }
        .active {
          color: rgba(67, 207, 124, 1);
        }
      }
    }
  }
}
.denglu {
  display: flex;
  align-items: center;
  margin-left: 50px;
  a {
    display: block;
    width: 116px;
    height: 36px;
    border-radius: 4px;
    background: rgba(173, 255, 206, 0.2);
    backdrop-filter: blur(10px);
    text-align: center;
    line-height: 36px;

    color: rgba(67, 207, 124, 1);
  }
  .head {
    width: 44px;
    height: 44px;
    img {
      border-radius: 22px;
    }
  }
  .down {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    cursor: pointer;
  }
}
.loginInfo {
  width: 242px;
  height: 229px;
  padding: 20px 18px;
  position: absolute;
  top: 6rem;
  right: 0;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  backdrop-filter: blur(15px);

  line-height: 1.5;

  .member {
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    .memberitem {
      width: 206px;
      height: 52px;
      padding: 6px 0 6px 10px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      .end {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .general {
      // background: linear-gradient(
      //   131.04deg,
      //   rgba(186, 220, 230, 1) 0%,
      //   rgba(183, 224, 237, 1) 54.18%,
      //   rgba(163, 221, 240, 1) 100%
      // );
      background: url("./images/genaral.png") no-repeat;
    }
    .gold {
      background: url("./images/gold.png") no-repeat center;
      background-size: cover;
    }
  }
  .infoOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .infohead {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        border-radius: 20px;
      }
    }
    .infotwo {
      line-height: 24px;
      flex: 1;

      overflow: hidden;
    }
    .edit {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .infothree {
    padding: 8px 10px;
    line-height: 2;
    p {
      cursor: pointer;
    }
  }
}
// (min-width: 700px) and
@media (max-width: 1116px) {
  .smallnav {
    display: flex !important;
  }
  .bignav {
    display: none !important;
  }
}
// @media (min-width: 1024px) {
//   .smallnav {
//     display: none;
//   }
//   .bignav {
//     display: block;
//   }
// }
</style>